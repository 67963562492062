<template>
  <div>
    <b-card>
      <b-row align-v="center">
        <b-col>
          <h6>
            {{ `${$t('fields.result')} (${total})` }}
          </h6>
        </b-col>
        <b-col
          cols="3"
          class="text-right"
        >
          <b-button
            v-if="$allowPermission('agent:manage.user-agent')"
            variant="relief-primary"
            to="/assistants/create"
          >
            <feather-icon
              icon="PlusIcon"
            />
            {{ $t('buttons.add') }}
          </b-button>
        </b-col>
      </b-row>
        
      <b-row
        align-h="center"
        class="my-2"
      >
        <b-col>
          <b-input-group>
            <b-form-input
              v-model="search"
              type="search"
              :placeholder="`${$t('fields.search')}... `"
            />
          </b-input-group>
        </b-col>
        <b-col cols="3">
          <master-select-input 
            :value="selectedMaster"
            hide-label
            @update="onMasterIdChange"
          />
        </b-col>
        <b-col cols="3">
          <agent-select-input
            :value="selectedAgent"
            :master-id="selectedMaster"
            hide-label
            @update="onAgentIdChange"
          />
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        :items="assistantList"
        :fields="fields"
        :busy="isFetching"
        responsive
        show-empty
      >
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(agent)="data">
          {{ data.value.name }}
        </template>
        <template #cell(creator)="data">
          <span v-if="data.value">
            {{ data.value.name }}
            ({{ data.value.username }})
          </span>
          <span v-else>
            -
          </span>
        </template>
        <template #cell(createdAt)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(id)="data">
          <b-dropdown
            v-if="$allowPermission('agent:manage.user-agent')"
            variant="info"
            size="sm"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="SettingsIcon"
                size="20"
              />
            </template>
            <b-dropdown-item :to="`/assistants/detail/${data.item.id}`">
              <feather-icon
                icon="EditIcon"
                class="mr-1"
              />
              {{ $t('buttons.edit') }}
            </b-dropdown-item>
            <b-dropdown-item @click="toggleResetPasswordModal(data.item)">
              <feather-icon
                icon="UnlockIcon"
                class="mr-1"
              />
              {{ $t('buttons.reset_password') }}
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item
              variant="danger"
              @click="onDelete(data.value)"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-1"
              />
              <span>
                {{ $t('buttons.remove') }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">
            {{ $t('messages.nothing_here') }}
          </p>
        </template>
      </b-table>
      <!-- pagination -->
      <b-row>
        <b-col md="4">
          <page-limit-select
            :value="limit"
            @update="onLimitChange"
          />
        </b-col>
        <b-col>
          <pagination-input
            :per-page="limit"
            :total="total"
            @update="(val) => currentPage = val"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      v-model="isCreditTransferModalActive"
      :title="`${$t('credits.direct_transfer')} ( ${$t('routes.VIEW_ASSISTANTS')}-${selectedAssistant.username|| ''})`"
      hide-footer
    >
      <DirectCreditTransferForm @close="onCloseModal" />
    </b-modal>
    <ResetPasswordFormModal
      ref="assistantResetPassword"
      :loading="isUpdatingAssitant"
      no-btn
      @reset-password="onSubmitResetPassword"
    />
  </div>
</template>

// TODO no pagination
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  page: {
    title: 'Assistant List',
  },
  components: {
    DirectCreditTransferForm: () =>
      import('@/views/common-forms/DirectCreditTransferForm.vue'),
    ResetPasswordFormModal: () =>
      import('@/views/common-forms/ResetPasswordFormModal.vue'),
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,
      isCreditTransferModalActive: false,
      selectedAssistant: {},
      selectedAgent: '',
      selectedMaster: '',
      onSearchTimeout: null,
      search: '',
      fields: [
        '#',
        { key: 'agent', label: 'เอเย่นต์' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        { key: 'name', label: 'ชื่อ' },
        { key: 'phoneNumber', label: this.$t('user.phone') },
        { key: 'creator', label: 'คนสร้าง' },
        { key: 'createdAt', label: 'สร้างเมื่อ' },
        {
          key: 'id',
          label: '',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.assistant.isFetchingAssistants || state.assistant.isDeletingAssistant,
      isUpdatingAssitant: (state) => state.assistant.isUpdatingAssistant,
      isUpdatingAssistantSuccess: (state) => state.assistant.isUpdatingAssistantSuccess,
    }),
    ...mapGetters(['assistants']),
    assistantList() {
      const { items } = this.assistants
      return items || []
    },
    pagination() {
      return this.assistants.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    search() {
      this.delaySearch()
    },
    currentPage(val) {
      if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  
  methods: {
    ...mapActions([
      'fetchAssistants',
      'updateAssistant',
      'deleteAssistant',
    ]),
    fetchData() {
      this.fetchAssistants({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search, 
        agentId: this.selectedAgent, 
        masterId: this.selectedMaster,
      })
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onLimitChange(limit) {
      if(limit){
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    toggleModal(user) {
      this.isCreditTransferModalActive = !this.isCreditTransferModalActive
      this.selectedAssistant = user
    },
    onCloseModal() {
      this.isCreditTransferModalActive = false
      this.selectedAssistant = {}
    },
    toggleResetPasswordModal(user) {
      this.$refs.assistantResetPassword.toggleModal()
      this.selectedAssistant = user
    },
    onCloseResetPasswordModal() {
      this.$refs.assistantResetPassword.onClose()
      this.selectedAssistant = {}
    },
    async onSubmitResetPassword({ password }) {
      await this.updateAssistant({
        id: this.selectedAssistant.id,
        data: {
           name: this.selectedAssistant.name, 
           phoneNumber: this.selectedAssistant.phoneNumber,
           password,
        },
      })
      if(this.isUpdatingAssistantSuccess) {
        this.onCloseResetPasswordModal()
      }
    },
    onDelete(assistantId) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((value) => {
          if (value) {
            this.deleteAssistant(assistantId)
          }
        })
    },
  },
}
</script>